<template>
  <sdModal
    centered
    type="primary"
    :title="data.type ? i18n.t(`accounts.invest.${data.type}`) : i18n.t(`accounts.public.invest`)"
    :visible="data.visible"
    :onCancel="closeModal"
    :width="500"
    class="publish-modal"
  >
    <a-form :model="dataSource" :rules="rules" @finish="modalAction" name="invest" ref="invest">
      <a-alert :message="error" type="warning" v-if="error" show-icon />
      <a-form-item
        ref="investAccount"
        name="investAccount"
        class="input__wrapper"
        :label="i18n.t('start.writeInAccount')"
        v-if="data.type"
      >
        <a-input :value="writeInAccount" size="large" type="text" :disabled="true" style="width: 100%" />
      </a-form-item>
      <a-form-item ref="writeOff" name="writeOff" class="input__wrapper" :label="i18n.t('start.writeOffAccount')">
        <a-input :value="writeOffAccount" size="large" type="text" :disabled="true" style="width: 100%" />
      </a-form-item>
      <a-form-item
        ref="descriptionAccount"
        name="descriptionAccount"
        class="input__wrapper mb-20 mt-10"
        :label="i18n.t('publish.modal.input_description')"
        v-if="data.data?.description"
      >
        <p style="width: 100%">
          {{ data.data?.description }}
        </p>
      </a-form-item>

      <a-form-item ref="amount" name="amount" class="input__wrapper" :label="i18n.t('start.summ')">
        <a-input
          v-model:value="dataSource.amount"
          size="large"
          style="width: 100%"
          type="number"
          :required="true"
          step="0.01"
        >
          <template #addonAfter>
            <p class="action__title">USDT</p>
          </template>
        </a-input>
      </a-form-item>
      <div class="modal-buttons df-jcsb-aic">
        <sdButton class="action__button" size="large" :disabled="isLoading" type="danger" @click="closeModal" raised>
          {{ i18n.t('start.cancel') }}
        </sdButton>
        <sdButton
          class="action__button"
          size="large"
          :disabled="isLoading || errorAmount"
          type="primary"
          htmlType="submit"
          raised
        >
          {{ data.type ? i18n.t(`accounts.invest.${data.type}`) : i18n.t(`accounts.public.invest`) }}
        </sdButton>
      </div>
    </a-form>
  </sdModal>
</template>

<script>
import { computed, reactive, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {
          visible: false,
          data: null,
          type: null,
        };
      },
    },
  },

  setup(props, { emit }) {
    const { state, dispatch } = useStore();
    const i18n = reactive(useI18n());
    const router = useRouter();

    const showTerms = ref(false);
    const dataSource = ref({ amount: 0 });
    const errorAmount = ref(false);

    const mainAccount = computed(() => state.accounts.mainAccount);
    const isLoading = computed(() => state.publicAccounts.loading);
    // const error = computed(() => state.publicAccounts.error);

    const writeInAccount = ref();
    const writeOffAccount = ref();

    let max = ref();

    const error = computed(() => {
      const _error = state.publicAccounts.error;
      if (_error) {
        if (typeof _error === 'object') {
          if (typeof _error.errors === 'string') {
            return _error.errors;
          } else {
            return Object.entries(_error).map((value) => value[1][0]);
          }
        } else {
          return _error == 500 ? i18n.t('error.500') : _error;
        }
      }
      return false;
    });

    watchEffect(() => {
      const mainName = i18n.t('start.inputAccount', {
        name: i18n.t('start.name' + mainAccount.value?.attributes?.kind),
        amount: mainAccount.value?.attributes?.amount,
      });

      let secondary = `${i18n.t('accounts.invest.name') + props.data.data?.name} `;
      if (props.data.data?.amount && props.data.data?.amount_currency) {
        secondary = `${secondary} ( ${props.data.data?.amount} ${props.data.data?.amount_currency} )`;
      }

      if (props.data.type === 'withdrawal') {
        writeInAccount.value = mainName;
        writeOffAccount.value = secondary;

        max.value = props.data.data?.amount;
      } else {
        writeInAccount.value = secondary;
        writeOffAccount.value = mainName;

        max.value = mainAccount.value?.attributes?.amount;
      }
    });

    const closeModal = () => {
      dispatch('clearPublicErrors');
      emit('update:data', { visible: false, data: props.data.data, type: props.data.type });
      // setTimeout(() => {
      //   emit('update:data', { visible: false, data: null, type: null });
      // }, 50);
    };

    const amountValidation = () => {
      if (!rules.amount[0].trigger[1]) {
        rules.amount[0].trigger[1] = 'change';
      }

      if (+max.value < 10) {
        return Promise.reject(i18n.t('messages.amount_min'));
      }
      // console.log(max);
      const validate = { min: 10, max: +max.value };

      if (validate.min > dataSource.value.amount || validate.max < dataSource.value.amount) {
        errorAmount.value = true;
        return Promise.reject(i18n.t('messages.errorAmount', validate, 2) + ' USDT');
      } else {
        errorAmount.value = false;
        return Promise.resolve();
      }
    };

    const modalAction = async () => {
      let data = {
        amount: dataSource.value.amount,
      };
      if (props.data.type === 'withdrawal') {
        data = {
          account_from_id: props.data.data.id,
          account_to_id: mainAccount.value.id,
          ...data,
        };
      } else if (props.data.type === 'deposit') {
        data = {
          account_from_id: mainAccount.value.id,
          account_to_id: props.data.data.id,
          ...data,
        };
      } else {
        data = {
          name: props.data.data.name,
          id: props.data.data.id,
          ...data,
        };
      }
      if (props.data.type) {
        await dispatch('sendPublicTransfer', data);
      } else {
        await dispatch('createInvestorAccount', data);
        if (!error.value) {
          router.push('/trading/invest');
        }
      }
      if (!error.value) {
        closeModal();
      }
      return;
    };

    const rules = reactive({
      amount: [
        {
          trigger: ['blur'],
          type: 'number',
          validator: amountValidation,
        },
      ],
    });

    return {
      i18n,
      isLoading,
      dataSource,
      showTerms,
      writeInAccount,
      writeOffAccount,
      errorAmount,
      rules,
      mainAccount,
      error,
      modalAction,
      closeModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-buttons {
  margin-top: 20px;
  gap: 10px;
  flex-wrap: nowrap;
  button {
    width: 50%;
  }
}
</style>
